import React, {useState, useEffect} from 'react';

import styles from './DetailProject.module.scss';

import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { VscClose } from 'react-icons/vsc';



export default function DetailProject(props) {

    let { handleClick } = props;

    const [ projectsListImg, setProjectsListImg ] = useState(props.projectsListImg)
    const [ arrayImgIndex, setArrayImgIndex ] =useState(props.arrayImgIndex);
    const [ actualImg, setActualImg] = useState(projectsListImg[arrayImgIndex])




    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            setArrayImgIndex(null)
            setActualImg(null)
        };
        // eslint-disable-next-line
    }, [])

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            props.handleClick(e);
        }
    }


    const handleChangeImage = (e) => {
        let whichArrow = e.target.dataset;
        if ( whichArrow.right ) {
            let newArrayImg = projectsListImg;
            newArrayImg.push(projectsListImg.shift());
            setProjectsListImg(newArrayImg);
            setActualImg(newArrayImg[arrayImgIndex])
        } else {
            let newArrayImg = projectsListImg;
            newArrayImg.unshift(projectsListImg.pop());
            setProjectsListImg(newArrayImg);
            setActualImg(newArrayImg[arrayImgIndex])
        }
    }




    return (
        <aside className={styles.detailContainer}
            onClick={handleClick}>



            <div className={styles.imageWrapper}>
                <VscClose fill="#8c8c8c"
                    stroke-width="0.6"
                    size="116"
                    className={styles.closeIcon}
                    onClick={handleClick}/>
                <div
                    className={styles.arrowLeft}
                    data-left
                    onClick={(e) => handleChangeImage(e)} >
                    <BsChevronLeft fill="#8c8c8c"
                        stroke-width="0.5"
                        size="116" className={styles.icon}/>
                </div>

                <img src={actualImg}
                    alt='projekt'
                    className={styles.detailImage} />

                <div
                    className={styles.arrowLeft}
                    data-right
                    onClick={(e) => handleChangeImage(e)} >
                    <BsChevronRight fill="#8c8c8c"
                        stroke-width="0.5"
                        size="116" className={styles.icon} />
                </div>

            </div>

        </aside>
    )
}
